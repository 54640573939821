import { useLocation } from 'react-router-dom';
import { SubPortalKey } from '../../types';
import { useIsApplicationPageEnabled } from '../../featureFlag/useIsSuspiciousAppsPageEnabled';

/**
 * Returns the current portal key based on the current URL.
 */
export const useCurrentPortalKey = (): SubPortalKey => {
  const { pathname } = useLocation();
  const isApplicationEnabled = useIsApplicationPageEnabled();
  if (pathname.includes('abuse')) {
    return SubPortalKey.abuse;
  }
  if (pathname.includes('infringement')) {
    return SubPortalKey.infringement;
  }
  if (pathname.includes('developer')) {
    return SubPortalKey.developer;
  }
  if (isApplicationEnabled && pathname.includes('application')) {
    return SubPortalKey.application;
  }
  return SubPortalKey.report;
};
