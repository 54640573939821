/**
 * Represents the reason for an app threat report. This is a subset of the
 * MSRC Reporting `IncidentType` enum.
 */
export enum AppThreatReportReason {
  AppHasFraudulentPublisher = 'AppHasFraudulentPublisher',
  AppSuspicious = 'AppSuspicious',
  AppMisuseOfData = 'AppMisuseOfData',
}

/**
 * Represents possible sources for an app threat report.
 */
export enum AppThreatReportSource {
  /**
   * Indicates that the report was submitted via a web form.
   */
  Web = 'Web',
  /**
   * Indicates that the report was submitted via an API.
   */
  API = 'API',
  Unknown = 'Unknown',
}

/**
 * Represents an interface for a threat report.
 */
export interface IApplicationThreatReport {
  /**
   * The reason for the app threat report.
   */
  reportReason: AppThreatReportReason;

  /**
   * The date and time the report was submitted.
   */
  reportedDateTime?: Date;

  /**
   * The ID of the application conducting the suspicious activity.
   */
  reportedAppId?: string;
  /**
   * The description of the threat report.
   */
  comment: string;

  /**
   * Indicates whether the submission is from a website or a direct API submission.
   */
  reportSource: AppThreatReportSource;
  /**
   * The time zone that the user selected of the incident.
   */
  timeZone?: string;
}

/**
 * Represents the state of the "application threat report" form.
 */
export interface IApplicationThreatReportFormState
  extends Partial<IApplicationThreatReport> {}
