import { AnalyticsProvider } from '@msrceng/analytics';
import { ReactNode } from 'react';

/**
 * Analytics wrapper for the application.
 */
export const Analytics = ({ children }: { children: ReactNode }) => {
  const { applicationInsightsKey } = (window as any).appConfig;
  const roleName = 'CertPortal';
  return (
    <AnalyticsProvider
      applicationInsightsKey={applicationInsightsKey}
      roleName={roleName}
    >
      {children}
    </AnalyticsProvider>
  );
};
