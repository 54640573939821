import { useFeature } from '@msrceng/component';

/**
 * Feature flag for the Suspicious Application page.
 */
const FEATURE_FLAG_APPLICATION_PAGE = 'ApplicationPage';

// Note: leave this here in case we need to turn it off quickly.
/**
 * Returns true if the Suspicious Application page is enabled.
 */
export const useIsApplicationPageEnabled = () => {
  return true; // return useIsFeatureFlagEnabled(FEATURE_FLAG_APPLICATION_PAGE);
};

/**
 * Feature controller for the Suspicious Application page.
 */
export const useSuspiciousApplicationPageFeature = () =>
  useFeature({
    featureName: FEATURE_FLAG_APPLICATION_PAGE,
    label: 'Suspicious Application Page',
    description:
      'Updates the abuse report page to take advantage of the newly created landing page.',
    // isInternalOnly: true,
    showInFeatureToggleUI: false,
  });
