import { PageHeading } from 'components/PageHeading/PageHeading';
import { FullPage1600pxLayoutStack } from '@msrceng/component';
import { MessageBar, MessageBarType } from '@fluentui/react';

/**
 * When used with `ErrorBoundary` (see `App.tsx`), the exception will be automatically tracked in
 * Application Insights. We don't need to use `useAnalytics` and make our own `trackException` call.
 *
 * @deprecated Use the MSRCEng common AppErrorBoundary instead (which is a fork off this component):
 * ```tsx
 * import { AppErrorBoundary } from '@msrceng/component';
 * ```
 */
export const ErrorPage = () => (
  <FullPage1600pxLayoutStack>
    <div role='alert'>
      <PageHeading>Something went wrong.</PageHeading>

      <MessageBar messageBarType={MessageBarType.error}>
        Sorry, an has error occured. We have been notified and plan to analyze
        the issue.
      </MessageBar>
    </div>
  </FullPage1600pxLayoutStack>
);
