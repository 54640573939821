import { Default404Page } from '@msrceng/component';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NoticeSubmittedPage } from 'pages/NoticeSubmittedPage/NoticeSubmittedPage';
import { ReportSubmittedPage } from 'pages/ReportSubmittedPage/ReportSubmittedPage';
import { ErrorPage } from 'pages/ErrorPage/ErrorPage';
import {
  ReportAbusePage,
  DeveloperPage,
  ReportInfringementPage,
  SuspiciousApplicationPage,
  ReportLandingPage,
  SuspiciousAppSubmittedPage,
} from './LazyImports';
import { useIsApplicationPageEnabled } from '../../featureFlag/useIsSuspiciousAppsPageEnabled';
import { useIsLandingPageEnabled } from '../../featureFlag/useIsLandingPageEnabled';

/**
 * The router for the report portal.
 */
export const ReportRouter = () => {
  const isApplicationEnabled = useIsApplicationPageEnabled();
  const isLandingPageEnabled = useIsLandingPageEnabled();

  return (
    <Switch>
      <Route exact path='/'>
        <ReportLandingPage />
      </Route>
      {/* Todo: delete this when landing page is shipped. */}
      {!isLandingPageEnabled && <Redirect exact from='/' to='/abuse' />}
      {process.env.PUBLIC_URL && (
        <Redirect
          from={process.env.PUBLIC_URL + process.env.PUBLIC_URL}
          to='/abuse'
        />
      )}
      <Route path={'(.*/auth)'}>
        {/*
                            Intentionally empty, this should never even be hit because AppShell catches it
                            but this is here just in case so the user doesn't have a 404
                            */}
      </Route>

      <Route path='/abuse/submitted'>
        <ReportSubmittedPage />
      </Route>

      <Route path='/abuse'>
        <ReportAbusePage />
      </Route>

      <Route exact path='/developer'>
        <DeveloperPage />
      </Route>

      <Route path='/infringement/submitted'>
        <NoticeSubmittedPage />
      </Route>

      <Route path='/infringement'>
        <ReportInfringementPage />
      </Route>

      <Route path='/application/submitted'>
        <SuspiciousAppSubmittedPage />
      </Route>

      {isApplicationEnabled && (
        <Route path='/application'>
          <SuspiciousApplicationPage />
        </Route>
      )}

      {/* "Hidden" route useful for verifying the result of the `ErrorBoundary` */}
      <Route exact path='/error'>
        <ErrorPage />
      </Route>

      {/* Fallback, catchall route for 404s */}
      <Route>
        <Default404Page />
      </Route>
    </Switch>
  );
};
