// This must be the first line
import '@msrceng/component/lib/InternetExplorer/polyfill';

import { createRoot } from 'react-dom/client';
import { App } from 'components/App/App';
import 'normalize.scss';
import 'index.scss';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
