import { Text, DefaultButton } from '@fluentui/react';
import { NotificationCard } from 'components/NotificationCard/NotificationCard';
import {
  FeedbackFivePointEveryXBusinessDays,
  ExternalLink,
  FullPage1600pxLayoutStack,
} from '@msrceng/component';

/**
 * Page displayed after a report has been submitted.
 */
export const ReportSubmittedPage = () => {
  const headingText = 'Thank you for submitting your report!';

  return (
    <FullPage1600pxLayoutStack>
      <NotificationCard
        heading={headingText}
        primaryButton={{
          path: '/abuse',
          text: 'Submit another report',
        }}
      >
        <Text as='p' block>
          Microsoft takes abuse seriously. We have a variety of specialized
          teams working 24/7 to protect the security and privacy of our
          customers and to investigate and remediate reports of abuse. Through
          systems such as{' '}
          <ExternalLink
            target='_blank'
            href='https://azure.microsoft.com/services/security-center/'
          >
            Azure Security Center
          </ExternalLink>{' '}
          and{' '}
          <ExternalLink
            target='_blank'
            href='https://products.office.com/exchange/advance-threat-protection'
          >
            Office 365 Advanced Threat Protection
          </ExternalLink>
          , Microsoft protects customers from unsafe links and other threats.
          Microsoft uses these systems, along with industry-wide cybersecurity
          threat programs, automation, and machine learning to detect, identify,
          fight abuse, and to keep our customers safe.
        </Text>
        <Text as='p' block>
          We value community feedback such as yours – to help us identify and
          take action against abuse of Microsoft Online Services.
        </Text>

        <FeedbackFivePointEveryXBusinessDays
          source={'CertSubmit'}
          businessDaysBetweenDismiss={1}
          businessDaysBetweenRequests={1}
        >
          How satisfied are you with the MSRC Cert Portal?
        </FeedbackFivePointEveryXBusinessDays>

        <DefaultButton href='https://www.microsoft.com/msrc'>
          MSRC Home
        </DefaultButton>
      </NotificationCard>
    </FullPage1600pxLayoutStack>
  );
};
