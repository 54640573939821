/**
 * Get's the proper title for the page based on path
 * @param pathname name of the path in the url
 * @returns updated title
 */
export const getTitleFromPath = (pathname: string): Array<string> => {
  switch (pathname) {
    case '/abuse/submitted':
      return ['Report Submitted', 'Thank you for submitting your report!'];
    case '/developer':
      return ['Developer API Documentation | MSRC'];
    case '/infringement/submitted':
      return ['Notice Submitted', 'Thank you for your notice'];
    case '/infringement':
      return ['Infringement Report'];
    default:
      return ['Abuse Report'];
  }
};
