import { useIsApplicationPageEnabled } from '../../featureFlag/useIsSuspiciousAppsPageEnabled';
import { useCurrentPortalKey } from './useCurrentPortalKey';

/**
 * A hook to select the portal name and path info for the Appbar.
 */
export const usePortalNameAndPath = () => {
  const currentPortal = useCurrentPortalKey();
  const isApplicationEnabled = useIsApplicationPageEnabled();

  switch (currentPortal) {
    case 'abuse':
      return {
        portalHomeHref: '/abuse',
        portalName: 'Report Abuse',
      };
    case 'infringement':
      return {
        portalHomeHref: '/infringement',
        portalName: 'Infringement Notice',
      };
    case 'developer':
      return {
        portalHomeHref: '/developer',
        portalName: 'Developer',
      };
    case 'application':
      if (isApplicationEnabled) {
        return {
          portalHomeHref: '/application',
          portalName: 'OAuth Application',
        };
      }
      break;
    case 'report':
    default:
      break;
  }
  return {
    // portalHomeHref: '/report',
    portalHomeHref: '/',
    portalName: 'Report',
  };
};
