import { PartialTheme } from '@fluentui/react/lib/Theme';
const pxToRem = (pixels: number): string => `${pixels / 16}rem`;

const sharedCardRootStyles = {
  marginBottom: '2.2em',
  marginTop: '2.2em',
  maxWidth: '789px',
};

const dropdownDescriptionStyles = {
  styles: {
    root: {
      marginBottom: pxToRem(24),
      marginTop: pxToRem(-24),
      maxWidth: '340px',
    },
  },
};

const sharedFormFieldStyles = {
  marginBottom: pxToRem(24),
  maxWidth: '340px',
};

const actionButtonFormAlignmentStyles = {
  // Left-aligning the icon in `ActionButton` with form elements on the page
  left: '-9px',
};

// To avoid auto-zoom in iOS when fields receive focus, bump the `font-size` up to 16px (from 14px)
// and re-set using a media query
const fontSizeStyles = {
  fontSize: pxToRem(16),
  selectors: {
    '@media (min-width: 640px)': {
      fontSize: pxToRem(14),
    },
  },
};

const scopedSettings = {
  Checkbox: {
    styles: {
      root: sharedFormFieldStyles,
    },
  },

  ChoiceGroup: {
    styles: {
      root: {
        marginBottom: pxToRem(16),
      },
    },
  },

  Dropdown: {
    styles: {
      root: sharedFormFieldStyles,
      title: fontSizeStyles,
    },
  },

  IconButton: {
    styles: {
      root: {
        height: '20px',
        selectors: {
          ':hover': {
            background: 'none !important',
            cursor: 'default',
          },
        },
        width: '20px',
      },
    },
  },

  Link: {
    styles: {
      root: {
        textDecoration: 'underline',
      },
    },
  },

  Label: {
    styles: {
      root: {
        display: 'inline-block',
      },
    },
  },

  PrimaryButton: {
    styles: {
      root: {
        margin: `${pxToRem(16)} 0`,
      },
    },
  },

  Spinner: {
    styles: {
      label: {
        textAlign: 'left',
      },
      root: {
        justifyContent: 'left',
      },
    },
  },

  TextField: {
    styles: {
      field: {
        ...fontSizeStyles,
        selectors: {
          ...fontSizeStyles.selectors,
          '&::placeholder': fontSizeStyles,
        },
      },
      root: {
        ...sharedFormFieldStyles,
        fontSize: pxToRem(12),
      },
    },
  },
};

// Exports
// -------

const localTheme: PartialTheme = {
  components: scopedSettings,
};

export {
  actionButtonFormAlignmentStyles,
  sharedCardRootStyles,
  sharedFormFieldStyles,
  dropdownDescriptionStyles,
  localTheme,
};
