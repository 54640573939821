import {
  ThemeProvider as LocalThemeProvider,
  initializeIcons,
} from '@fluentui/react';
import { DocumentElementBackground, ThemeProvider } from '@msrceng/theme';
import { ReactNode } from 'react';
import { localTheme } from 'utilities/fluentui/scopedSettings';

/**
 * Theming wrapper for the entire application.
 */
export const ThemeSetup = ({ children }: { children: ReactNode }) => {
  // https://github.com/microsoft/fluentui/wiki/Using-icons
  initializeIcons();

  return (
    <ThemeProvider>
      <LocalThemeProvider style={{ height: 'inherit' }} theme={localTheme}>
        <DocumentElementBackground />
        {children}
      </LocalThemeProvider>
    </ThemeProvider>
  );
};
