import { AppThreatReportReason } from 'utilities/forms/interfaces/suspiciousAppTypes';

/**
 * All allowed threat type keys. These represent possible categories on the selector.
 */
export enum ThreatType {
  AzureSubscription = 'AzureSubscription',
  IpAddress = 'IpAddress',
  CommunityGallery = 'CommunityGallery',
  URL = 'URL',
  Security = 'Security Incident',
  OAuthApplication = 'OAuthApplication',
  Infringement = 'Infringement',
  Other = 'Other',
}

/**
 * These incident types are related to infringement reporting.
 */
export enum InfringementIncidentType {
  Infringement = 'Infringement',
}

/**
 * These incident types are related abuse reporting.
 */
export enum AbuseIncidentType {
  AzureAccountCompromise = 'AzureAccountCompromise',
  BingBot = 'BingBot',
  BruteForce = 'BruteForce',
  DenialOfService = 'DenialOfService',
  MaliciousArtifact = 'MaliciousArtifact',
  MaliciousTextorURL = 'MaliciousTextorURL',
  IllegalViolatesTheRightsOfOthers = 'IllegalViolatesTheRightsOfOthers',
  Malware = 'Malware',
  Other = 'Other',
  Privacy = 'Privacy',
  Phishing = 'Phishing',
  ResponsibleAI = 'ResponsibleAI',
  Spam = 'Spam',
}

/**
 * These incident types are related to security vulnerability reporting.
 */
export enum SecurityIncidentType {
  Vulnerability = 'Vulnerability',
}

/** These incident types are used to redirect to external forms. */
export enum ExternalIncidentType {
  CSEAI = 'CSEAI',
  OutlookSpam = 'OutlookSpam',
  Subpoena = 'Subpoena',
  TechSupport = 'TechSupport',
  UnsafeSiteOrURL = 'UnsafeSiteOrURL',
}

// We declare both a type and a const with the same name and values to
// replicate the behavior of an enum, but allowing us to compose this
// enum from multiple other enums.

/**
 * A union of all possible incident types.
 */
export type ReportGuideIncidentType =
  | AppThreatReportReason
  | AbuseIncidentType
  | SecurityIncidentType
  | ExternalIncidentType
  | InfringementIncidentType;

// Turn off this ES lint rule because we're creating a fake enum.
/* eslint-disable @typescript-eslint/no-redeclare, no-redeclare */
/**
 * A union of all possible incident types.
 */
export const ReportGuideIncidentType = {
  ...AppThreatReportReason,
  ...AbuseIncidentType,
  ...SecurityIncidentType,
  ...ExternalIncidentType,
  ...InfringementIncidentType,
} as const;
/* eslint-enable @typescript-eslint/no-redeclare, no-redeclare */

/**
 * A set of keys id identifying which sub-portal the user is on.
 */
export enum SubPortalKey {
  'abuse' = 'abuse',
  'infringement' = 'infringement',
  'developer' = 'developer',
  'application' = 'application',
  'report' = 'report',
}
