import { MsrcAuthContextConfig } from '@msrceng/auth';

export interface ICertAuthConfig extends MsrcAuthContextConfig {}

const baseConfig: ICertAuthConfig = {
  apiRoot: 'https://api.msrc-ppe.microsoft.com',
  applicationInsightsKey: 'e6b5c4da-5d33-43ce-8cf0-82ba180942a7',
  authority:
    'https://msrcweb.b2clogin.com/msrcweb.onmicrosoft.com/B2C_1A_TEST_MULTITENANT_SIGNUPSIGNIN',
  authorityPasswordReset:
    'https://msrcweb.b2clogin.com/msrcweb.onmicrosoft.com/B2C_1A_TEST_MULTITENANT_SIGNUPSIGNIN',
  b2cSignInPolicy: 'B2C_1A_TEST_MULTITENANT_SIGNUPSIGNIN',
  clientId: '1b2765bb-d560-4991-9826-7a8d71e712f4',
  scopes: [
    'openid',
    'profile',
    'https://msrcweb.onmicrosoft.com/msrc-api-ppe/user_impersonation',
  ],
};

const configList: Record<
  'dev' | 'ppe' | 'qa' | 'prd' | 'local',
  ICertAuthConfig
> = {
  dev: baseConfig,
  local: {
    ...baseConfig,
    // TODO: add the correct API ports here to run locally
    apiRoot: 'localhost:5000',
  },
  qa: {
    ...baseConfig,
    clientId: '8399de9f-d429-4752-9a4c-5e5366c00d6f',
    apiRoot: 'https://api.qa.msrc.windows.net',
    applicationInsightsKey: '135ebcbe-c395-48fd-a74f-f65ef482df2c',
  },
  ppe: { ...baseConfig, clientId: '8399de9f-d429-4752-9a4c-5e5366c00d6f' },
  prd: {
    ...baseConfig,
    apiRoot: 'https://api.msrc.microsoft.com',
    applicationInsightsKey: 'f34ddedf-e6a7-4209-b478-4fd9132e8f9e',
    authority:
      'https://msrcweb.b2clogin.com/msrcweb.onmicrosoft.com/B2C_1A_MULTITENANT_SIGNUPSIGNIN',
    authorityPasswordReset:
      'https://msrcweb.b2clogin.com/msrcweb.onmicrosoft.com/B2C_1A_MULTITENANT_SIGNUPSIGNIN',
    b2cSignInPolicy: 'B2C_1A_MULTITENANT_SIGNUPSIGNIN',
    clientId: '1d13e529-99b8-48fe-a77d-844853ae25bf',
    scopes: [
      'openid',
      'profile',
      'https://msrcweb.onmicrosoft.com/msrc-api/user_impersonation',
    ],
  },
};

// Exports
// -------

export default configList;
