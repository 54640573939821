import { FontSizes } from '@fluentui/react';

/**
 * Wraps children with `h1` HTML component and adds styling.
 */
export const PageHeading = ({ children }: { children: string }) => (
  <h1
    style={{
      fontSize: FontSizes.xLarge,
      margin: '2.5rem 0 1.5rem',
    }}
  >
    {children}
  </h1>
);
