import { AppLink, useFeature } from '@msrceng/component';

/**
 * Feature flag for the new landing page.
 */
const FEATURE_FLAG_NEW_LANDING_PAGE = 'LandingPage';

// Note: leave this here in case we need to turn it off quickly.
/**
 * Returns true if the new landing page is enabled.
 */
export const useIsLandingPageEnabled = () => {
  return true; // useIsFeatureFlagEnabled(FEATURE_FLAG_NEW_LANDING_PAGE);
};

/**
 * Feature controller for the new landing page.
 */
export const useLandingPageFeature = () =>
  useFeature({
    featureName: FEATURE_FLAG_NEW_LANDING_PAGE,
    label: 'New Landing Page',
    showInFeatureToggleUI: false,
    description: (
      <span>
        Enables the new landing page at <AppLink href='/'>/report</AppLink>{' '}
        which guides users through the user to the correct form or portal for
        what they want to report.
      </span>
    ),
  });
