import { ReactNode } from 'react';
import { AppConfigAndAuthProvider } from '@msrceng/auth';
import { AppConfigSaver } from 'components/AppConfigSaver/AppConfigSaver';
import configList from 'utilities/config/configList';

/**
 * Provides the app config and auth context to the app.
 */
export const AppConfig = ({ children }: { children: ReactNode }) => (
  <AppConfigAndAuthProvider {...{ configList }}>
    <AppConfigSaver>{children}</AppConfigSaver>
  </AppConfigAndAuthProvider>
);
