import { FullPage1600pxLayoutStack } from '@msrceng/component';
import { NotificationCard } from 'components/NotificationCard/NotificationCard';

/**
 * This is the infringement form's "Thank you" page.
 */
export const NoticeSubmittedPage = () => (
  <FullPage1600pxLayoutStack>
    <NotificationCard
      heading={'Thank you for your notice'}
      primaryButton={{
        path: '/infringement',
        text: 'Submit another notice',
      }}
    />
  </FullPage1600pxLayoutStack>
);
