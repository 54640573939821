import { Appbar } from '@msrceng/component';
import { useHistory } from 'react-router-dom';
import {
  siteHomeHref,
  siteName,
  supportEmailUrl,
  supportFormUrl,
} from 'utilities/config/shared';
import { usePortalNameAndPath } from 'utilities/hooks/usePortalNameAndPath';
import { ICommandBarItemProps } from '@fluentui/react';
import { useCurrentPortalKey } from '../../utilities/hooks/useCurrentPortalKey';

/**
 * App bar for the Cert portal.
 */
export const CertAppBar = () => {
  const items = useItems();

  const { portalHomeHref, portalName } = usePortalNameAndPath();

  return (
    <Appbar
      items={items}
      {...{
        portalHomeHref,
        portalName,
        siteHomeHref,
        siteName,
        supportEmailUrl,
        supportFormUrl,
      }}
    />
  );
};

const useItems = (): ICommandBarItemProps[] => {
  const currentPortal = useCurrentPortalKey();
  const developerItem = useDeveloperItem();
  const reportItem = useReportItem();

  switch (currentPortal) {
    case 'abuse':
      return [reportItem, developerItem];
    case 'developer':
      return [reportItem];
    case 'infringement':
      return [reportItem];
    case 'application':
      return [reportItem];
    case 'report':
      return [];
    default:
      return [];
  }
};

const useReportItem = (): ICommandBarItemProps => {
  const history = useHistory();
  return {
    iconProps: {
      iconName: 'ReportDocument',
    },
    key: 'report',
    onClick: () => history.push('/'),
    text: 'Report',
    title: 'Report something else',
  };
};

const useDeveloperItem = (): ICommandBarItemProps => {
  const history = useHistory();
  return {
    iconProps: {
      iconName: 'Code',
    },
    key: 'developer',
    onClick: () => history.push('/developer'),
    text: 'Developer',
  };
};
