import { AppShell } from '@msrceng/component';
import { CertAppBar } from 'components/CertAppBar/CertAppBar';
import { usePortalNameAndPath } from 'utilities/hooks/usePortalNameAndPath';

import {
  siteHomeHref,
  siteName,
  supportEmailUrl,
} from 'utilities/config/shared';
import { useCertFeatureList } from './useCertFeatureList';

/** A shim layer that exists because CERT  */
export const CertAppShell = ({ children }) => {
  const { portalHomeHref, portalName } = usePortalNameAndPath();

  const featureList = useCertFeatureList();

  return (
    <AppShell
      AppBar={CertAppBar}
      featureList={featureList}
      {...{
        portalHomeHref,
        portalName,
        siteName,
        siteHomeHref,
        supportEmailUrl,
      }}
    >
      {children}
    </AppShell>
  );
};
