/// This file only exists to isolate and simplify lazy loading of components.
/// DO NOT PUT ANYTHING ELSE IN HERE.

import { lazy } from 'react';

/**
 * Lazy import for `DeveloperPage`.
 */
export const DeveloperPage = lazy(async () => ({
  default: (await import('pages/DeveloperPage/DeveloperPage')).DeveloperPage,
}));

/**
 * Lazy import for `ReportAbusePage`.
 */
export const ReportAbusePage = lazy(async () => ({
  default: (await import('pages/ReportAbusePage/ReportAbusePage'))
    .ReportAbusePage,
}));

/**
 * Lazy import for `ReportInfringementPage`.
 */
export const ReportInfringementPage = lazy(async () => ({
  default: (await import('pages/ReportInfringementPage/ReportInfringementPage'))
    .ReportInfringementPage,
}));

/**
 * Lazy import for `SuspiciousApplicationPage`.
 */
export const SuspiciousApplicationPage = lazy(async () => ({
  default: (
    await import('pages/SuspiciousApplicationPage/SuspiciousApplicationPage')
  ).SuspiciousApplicationPage,
}));

/**
 * Lazy import for `ReportSubmittedPage`.
 */
export const SuspiciousAppSubmittedPage = lazy(async () => ({
  default: (
    await import('pages/SuspiciousAppSubmittedPage/SuspiciousAppSubmittedPage')
  ).SuspiciousAppSubmittedPage,
}));

/**
 * Lazy import for `ReportLandingPage`.
 */
export const ReportLandingPage = lazy(async () => ({
  default: (await import('pages/ReportLandingPage/ReportLandingPage'))
    .ReportLandingPage,
}));
