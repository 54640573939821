import { createRef, ReactNode, useEffect } from 'react';
import { Card } from '@coherence-design-system/controls';
import { Icon, PrimaryButton } from '@fluentui/react';
import { useHistory } from 'react-router-dom';

/**
 * Card meant to be displayed after a submission is made.  Note the header focus on load to address a11y issues found in  https://msazure.visualstudio.com/One/_workitems/edit/16164794
 */
export const NotificationCard = ({
  children,
  heading,
  primaryButton,
}: {
  children?: ReactNode;
  heading: string;
  primaryButton?: {
    path: string;
    text: string;
  };
}) => {
  const history = useHistory();
  const headingRef = createRef<HTMLHeadingElement>();

  useEffect(() => {
    if (headingRef.current) headingRef.current.focus();
  }, [headingRef]);

  return (
    <Card>
      <Icon
        iconName='Certificate'
        styles={{
          root: {
            alignItems: 'center',
            borderRadius: '100%',
            display: 'flex',
            fontSize: '21px',
            height: '46px',
            justifyContent: 'center',
            margin: '0 auto',
            width: '46px',
          },
        }}
      />

      <h1 ref={headingRef}>{heading}</h1>

      {children}

      {primaryButton && (
        <PrimaryButton
          className='card-button'
          href={`/report${primaryButton.path}`}
          onClick={event => {
            event.preventDefault();
            history.push(`${primaryButton.path}`);
          }}
        >
          {primaryButton.text}
        </PrimaryButton>
      )}
    </Card>
  );
};
