import { Announced } from '@fluentui/react';
import { useState, createRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getTitleFromPath } from './getTitleFromPath';

/**  Built to address this issue 
https://msazure.visualstudio.com/One/_workitems/edit/16164794
Title announcer made to set the appropriate title based on location and set the focus so a11y will work properly
*/
export const TitleAnnouncer = () => {
  const [title, setTitle] = useState('');
  const { pathname } = useLocation();
  const titleRef = createRef<HTMLSpanElement>();

  //https://github.com/remix-run/react-router/discussions/9863
  useEffect(() => {
    const newTitle = getTitleFromPath(pathname);
    if (newTitle) {
      const joinedNewTitle = newTitle.join(' ');
      if (title !== joinedNewTitle) {
        setTitle(joinedNewTitle);
        document.title = newTitle[0];
      }
    }
    if (titleRef.current) titleRef.current.focus();
    //commenting this out because it wants a dep on getTitleFromPath, but that ends up causing a loop issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, titleRef, title]);

  return (
    <>
      {title && <Announced message={title} />}
      {/* right now this is hidden, but might be useful if we want the focus to hit this instead of the announcement.  Better for a11y, but requires a bit of a UX redesign
      note because display: none the focus won't read this in a speech reader.  if you change that style, then you don't need the announce anymore */}
      <span
        tabIndex={-1}
        ref={titleRef}
        style={{ display: 'none' }}
        // role='status'
        // aria-live='polite'
      >
        {title}
      </span>
    </>
  );
};
