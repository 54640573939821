import { useAppConfig } from '@msrceng/auth';
import { ReactNode } from 'react';

/**
 * Tool for bypassing react for the one or two things that need app config data.
 */
export const AppConfigSaver = ({ children }: { children: ReactNode }) => {
  const config = useAppConfig();

  // Saving `config` globally to simplify usage across React Components and utility methods.
  // Avoids struggling with not being able to use the `useAppConfig` hook outside of Components.
  (window as any).appConfig = config;

  return <>{children}</>;
};
