import { Suspense } from 'react';
import { Spinner } from '@fluentui/react';
import {
  CookieConsentBanner,
  InternetExplorerMessageBar,
} from '@msrceng/component';
import { Analytics } from 'components/Analytics/Analytics';
import { AppConfig } from 'components/AppConfig/AppConfig';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeSetup } from 'components/ThemeSetup/ThemeSetup';
import { ErrorPage } from 'pages/ErrorPage/ErrorPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CertAppShell } from 'components/CertAppShell/CertAppShell';
import { TitleAnnouncer } from 'components/TitleAnnouncer/TitleAnnouncer';
import { ReportRouter } from './ReportRouter';

const queryClient = new QueryClient();

/** The root of the React app */
export const App = () => (
  <ThemeSetup>
    <BrowserRouter basename='/report'>
      <AppConfig>
        <Analytics>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <QueryClientProvider client={queryClient}>
              <CertAppShell>
                {/* <MessageApiDown /> */}
                <CookieConsentBanner />
                <InternetExplorerMessageBar />
                <TitleAnnouncer />
                <Suspense fallback={<Spinner label='Loading' />}>
                  <ReportRouter />
                </Suspense>
              </CertAppShell>
            </QueryClientProvider>
          </ErrorBoundary>
        </Analytics>
      </AppConfig>
    </BrowserRouter>
  </ThemeSetup>
);
